*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white-color: #ffffff;
  --faint-blue: #edfaed;
  --gray-color: #494949;
  --light-gray-color: #717171;
  --black-color: #000000;
  --green-color: #00bb00;
  --blue-color: #00bb00;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul,
ol[role="list"] {
  list-style-type: none;
}

a {
  text-decoration: none;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
